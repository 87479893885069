import React from "react";
import IconCourse from "../../images/Academy-course.png";
import IconGlobalNetwork from "../../images/Academy-Global-network.png";
import IconTogether from "../../images/Academy-together.png";
import IconSlack from "../../images/Academy-slack-icon.png";

import { color, typography } from "../../shared/style";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Text } from "../../components/Text/Text";
// import { Link } from "../../components/Link/Link";
import { RichText } from "prismic-reactjs";
import htmlSerializer from "../../components/RichtextHelper/CustomText";

const WhatYouCan = ({ list, data, ...props }) => {
  const { items, primary } = data || {};
  const Item = ({ data, step }) => (
    <Wrapper>
      <WrapperItem>
        <ItemHeader>
          <WrapImage>
            <Image src={data.icon.url}></Image>
          </WrapImage>
          <Step
            font={typography.type.code}
            color={color.dark.dark10}
            weight={typography.weight.regular1}
            size={typography.size.l3}
            lineHeight={56}
            capital="uppercase"
          >
            0{step}
          </Step>
        </ItemHeader>
        <Content
          font={typography.type.primary}
          color={color.netraul.black100}
          weight={typography.weight.bold}
          align="left"
          size={typography.size.m1}
          lineHeight={28}
        >
          {data.description.text}
        </Content>
      </WrapperItem>
    </Wrapper>
  );
  if(!items || !primary) return null;
  return (
    <WrapperSection>
      <HeaderContainer>
        <TitleHeader
          font={typography.type.secondary}
          color={color.primary.blue}
          weight={typography.weight.regular1}
          align="center"
          size={typography.size.l3}
          lineHeight={56}
          capital="uppercase"
        >
          <RichText render={primary?.title?.raw} htmlSerializer={htmlSerializer} />
        </TitleHeader>
      </HeaderContainer>
      <BodyContainer>
        {items && items.map((item, index) => (
          <Item key={index} data={item} step={index + 1}></Item>
        ))}
      </BodyContainer>
    </WrapperSection>
  );
};

const WrapperSection = styled.div`
  margin: auto;
  box-sizing: border-box;
  max-width: 1500px;
  margin-top: 120px;
  padding: 0 131px;
  @media (max-width: 1200px) {
    margin-top: 80px;
    padding: 0 40px;
  }
  @media (max-width: 992px) {
    margin-top: 80px;
    padding: 0 30px;
  }
  @media (max-width: 650px) {
    margin-top: 80px;
    padding: 0 20px;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TitleHeader = styled(Text)`
  display: block;
  max-width: 649px;
  letter-spacing: -0.015em;
  @media (max-width: 1200px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 650px) {
    font-size: 36px !important;
    line-height: 36px;
    font-weight: 500;
  }
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -30px;
  margin-top: 60px;
  box-sizing: border-box;
  align-items: stretch;
  @media (max-width: 1200px) {
    margin-top: 50px;
  }
  @media (max-width: 992px) {
    margin: 0 -20px;
  }
  @media (max-width: 650px) {
    margin: 0;
    margin-top: 20px;
  }
`;

const Wrapper = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 30px;
  box-sizing: border-box;
  overflow: hidden;
  @media (max-width: 1200px) {
    margin-top: 20px;
    flex: 0 0 50%;
    max-width: 50%;
  }
  @media (max-width: 992px) {
    padding: 0 20px;
  }
  @media (max-width: 650px) {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
  }
`;

const WrapperItem = styled.div`
  padding: 44px 36px 40px 44px;
  height: 100%;
  background-color: #f4f6f8;
  position: relative;
  @media (max-width: 650px) {
    padding: 40px 36px 50px 40px;
  }
`;
const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
const WrapImage = styled.div`
  max-width: 40px;
  max-height: 40px;
  width: 100%;
  height: 100%;
`;
const Step = styled(Text)`
  letter-spacing: -0.015em;
  position: absolute;
  top: 20px;
  right: 20px;
  opacity: 0.1;
`;
const Content = styled(Text)`
  display: block;
  margin-top: 32px;
  @media (max-width: 650px) {
    margin-top: 55px;
  }
`;

WhatYouCan.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      step: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

WhatYouCan.defaultProps = {
  list: [
    {
      title: "Learn from the tio experts",
      step: "01",
      icon: IconSlack,
    },
    {
      title: "$100,000 Pre-Seed Funding",
      step: "02",
      icon: IconCourse,
    },
    {
      title: "One-on one mentoring",
      step: "03",
      icon: IconTogether,
    },
    {
      title: "Official Certificate for LinkedIn and Resume",
      step: "04",
      icon: IconGlobalNetwork,
    },
  ],
};

export default WhatYouCan;
